html {
  // font-size: 16px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  // font-family: arial;
  font-size: 14px;
  background: #fff;
  font-family: 'Open Sans', sans-serif !important;
}

a:hover {
  text-decoration: none !important;
}

span .caret {
  display: none;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
}

body {
  // env-mode-prod
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  overscroll-behavior-y: none;
  background: linear-gradient(123deg, #d1cece, #afd7d4, #a993a1); //linear-gradient(123deg, #ffe2e2, #b5c6d8);
  background-attachment: fixed;
}

body.env-mode-non-prod {
  background: linear-gradient(to right, #a9bdc2, #71b280);
  background-attachment: fixed;
  .app-frame-sidebar {
    .zc-logo-dark {
      display: block;
    }
  }
}

// Universal styles
.header-snazzy {
  display: inline-block;
  font-weight: 800;
  background: -webkit-linear-gradient(234deg, #69618a, #0daf9f 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}