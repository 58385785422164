#tzc-loading-indicator {
  max-width: 100%;
}

.cls-1 {
  fill: url(#linear-gradient);
}

.cls-2 {
  fill: url(#linear-gradient-2);
}

.cls-3 {
  fill: url(#linear-gradient-3);
}

#tzc-loading-indicator {
  animation: spin 2s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
